.\!container {
  width: 100% !important;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .\!container {
    max-width: 640px !important;
  }

  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .\!container {
    max-width: 768px !important;
  }

  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .\!container {
    max-width: 1024px !important;
  }

  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .\!container {
    max-width: 1280px !important;
  }

  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .\!container {
    max-width: 1536px !important;
  }

  .container {
    max-width: 1536px;
  }
}

.\!visible {
  visibility: visible !important;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.collapse {
  visibility: collapse;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.\!absolute {
  position: absolute !important;
}

.absolute {
  position: absolute;
}

.\!relative {
  position: relative !important;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.\!left-0 {
  left: 0 !important;
}

.\!left-\[78px\] {
  left: 78px !important;
}

.\!top-0 {
  top: 0 !important;
}

.\!top-5 {
  top: 1.25rem !important;
}

.-left-12 {
  left: -3rem;
}

.-left-3 {
  left: -.75rem;
}

.-left-5 {
  left: -1.25rem;
}

.-top-11 {
  top: -2.75rem;
}

.-top-7 {
  top: -1.75rem;
}

.-top-9 {
  top: -2.25rem;
}

.bottom-0 {
  bottom: 0;
}

.bottom-\[10px\] {
  bottom: 10px;
}

.left-0 {
  left: 0;
}

.left-0\.5 {
  left: .125rem;
}

.left-1 {
  left: .25rem;
}

.left-1\.5 {
  left: .375rem;
}

.left-1\/2 {
  left: 50%;
}

.left-2 {
  left: .5rem;
}

.left-3 {
  left: .75rem;
}

.left-3\.5 {
  left: .875rem;
}

.left-4 {
  left: 1rem;
}

.left-5 {
  left: 1.25rem;
}

.left-60 {
  left: 15rem;
}

.left-7 {
  left: 1.75rem;
}

.left-\[-121px\] {
  left: -121px;
}

.left-\[-152px\] {
  left: -152px;
}

.left-\[-15px\] {
  left: -15px;
}

.left-\[-292px\] {
  left: -292px;
}

.left-\[-330px\] {
  left: -330px;
}

.left-\[-3px\] {
  left: -3px;
}

.left-\[-55px\] {
  left: -55px;
}

.left-\[-84px\] {
  left: -84px;
}

.left-\[1020px\] {
  left: 1020px;
}

.left-\[1025px\] {
  left: 1025px;
}

.left-\[103px\] {
  left: 103px;
}

.left-\[1047px\] {
  left: 1047px;
}

.left-\[1058px\] {
  left: 1058px;
}

.left-\[107px\] {
  left: 107px;
}

.left-\[1096px\] {
  left: 1096px;
}

.left-\[10px\] {
  left: 10px;
}

.left-\[111px\] {
  left: 111px;
}

.left-\[114px\] {
  left: 114px;
}

.left-\[1164px\] {
  left: 1164px;
}

.left-\[11px\] {
  left: 11px;
}

.left-\[120px\] {
  left: 120px;
}

.left-\[125px\] {
  left: 125px;
}

.left-\[131px\] {
  left: 131px;
}

.left-\[1321px\] {
  left: 1321px;
}

.left-\[133px\] {
  left: 133px;
}

.left-\[137px\] {
  left: 137px;
}

.left-\[1412px\] {
  left: 1412px;
}

.left-\[158px\] {
  left: 158px;
}

.left-\[165px\] {
  left: 165px;
}

.left-\[166px\] {
  left: 166px;
}

.left-\[17px\] {
  left: 17px;
}

.left-\[18px\] {
  left: 18px;
}

.left-\[19px\] {
  left: 19px;
}

.left-\[207px\] {
  left: 207px;
}

.left-\[20px\] {
  left: 20px;
}

.left-\[219px\] {
  left: 219px;
}

.left-\[220px\] {
  left: 220px;
}

.left-\[237px\] {
  left: 237px;
}

.left-\[26px\] {
  left: 26px;
}

.left-\[275px\] {
  left: 275px;
}

.left-\[287px\] {
  left: 287px;
}

.left-\[312px\] {
  left: 312px;
}

.left-\[353px\] {
  left: 353px;
}

.left-\[365px\] {
  left: 365px;
}

.left-\[375px\] {
  left: 375px;
}

.left-\[41px\] {
  left: 41px;
}

.left-\[420px\] {
  left: 420px;
}

.left-\[49px\] {
  left: 49px;
}

.left-\[50\%\] {
  left: 50%;
}

.left-\[50px\] {
  left: 50px;
}

.left-\[54px\] {
  left: 54px;
}

.left-\[560px\] {
  left: 560px;
}

.left-\[5px\] {
  left: 5px;
}

.left-\[602px\] {
  left: 602px;
}

.left-\[60px\] {
  left: 60px;
}

.left-\[622px\] {
  left: 622px;
}

.left-\[63px\] {
  left: 63px;
}

.left-\[682px\] {
  left: 682px;
}

.left-\[700px\] {
  left: 700px;
}

.left-\[705px\] {
  left: 705px;
}

.left-\[732px\] {
  left: 732px;
}

.left-\[78px\] {
  left: 78px;
}

.left-\[795px\] {
  left: 795px;
}

.left-\[7px\] {
  left: 7px;
}

.left-\[800px\] {
  left: 800px;
}

.left-\[843px\] {
  left: 843px;
}

.left-\[86px\] {
  left: 86px;
}

.left-\[959px\] {
  left: 959px;
}

.left-\[967px\] {
  left: 967px;
}

.left-\[9px\] {
  left: 9px;
}

.left-px {
  left: 1px;
}

.right-0 {
  right: 0;
}

.right-2 {
  right: .5rem;
}

.right-4 {
  right: 1rem;
}

.top-0 {
  top: 0;
}

.top-0\.5 {
  top: .125rem;
}

.top-1 {
  top: .25rem;
}

.top-10 {
  top: 2.5rem;
}

.top-11 {
  top: 2.75rem;
}

.top-2 {
  top: .5rem;
}

.top-2\.5 {
  top: .625rem;
}

.top-20 {
  top: 5rem;
}

.top-3 {
  top: .75rem;
}

.top-32 {
  top: 8rem;
}

.top-5 {
  top: 1.25rem;
}

.top-56 {
  top: 14rem;
}

.top-6 {
  top: 1.5rem;
}

.top-8 {
  top: 2rem;
}

.top-\[-104px\] {
  top: -104px;
}

.top-\[-110\], .top-\[-110px\] {
  top: -110px;
}

.top-\[-22px\] {
  top: -22px;
}

.top-\[-314px\] {
  top: -314px;
}

.top-\[-330px\] {
  top: -330px;
}

.top-\[-33px\] {
  top: -33px;
}

.top-\[-38px\] {
  top: -38px;
}

.top-\[-42px\] {
  top: -42px;
}

.top-\[-9px\] {
  top: -9px;
}

.top-\[10\%\] {
  top: 10%;
}

.top-\[104px\] {
  top: 104px;
}

.top-\[106px\] {
  top: 106px;
}

.top-\[109px\] {
  top: 109px;
}

.top-\[110px\] {
  top: 110px;
}

.top-\[113px\] {
  top: 113px;
}

.top-\[114px\] {
  top: 114px;
}

.top-\[117px\] {
  top: 117px;
}

.top-\[125px\] {
  top: 125px;
}

.top-\[131px\] {
  top: 131px;
}

.top-\[15px\] {
  top: 15px;
}

.top-\[170px\] {
  top: 170px;
}

.top-\[175px\] {
  top: 175px;
}

.top-\[17px\] {
  top: 17px;
}

.top-\[185px\] {
  top: 185px;
}

.top-\[187px\] {
  top: 187px;
}

.top-\[18px\] {
  top: 18px;
}

.top-\[19px\] {
  top: 19px;
}

.top-\[200px\] {
  top: 200px;
}

.top-\[202px\] {
  top: 202px;
}

.top-\[21px\] {
  top: 21px;
}

.top-\[220px\] {
  top: 220px;
}

.top-\[226px\] {
  top: 226px;
}

.top-\[22px\] {
  top: 22px;
}

.top-\[23px\] {
  top: 23px;
}

.top-\[243px\] {
  top: 243px;
}

.top-\[245px\] {
  top: 245px;
}

.top-\[254px\] {
  top: 254px;
}

.top-\[25px\] {
  top: 25px;
}

.top-\[272px\] {
  top: 272px;
}

.top-\[290px\] {
  top: 290px;
}

.top-\[299px\] {
  top: 299px;
}

.top-\[29px\] {
  top: 29px;
}

.top-\[300px\] {
  top: 300px;
}

.top-\[30px\] {
  top: 30px;
}

.top-\[316px\] {
  top: 316px;
}

.top-\[325px\] {
  top: 325px;
}

.top-\[343px\] {
  top: 343px;
}

.top-\[345px\] {
  top: 345px;
}

.top-\[34px\] {
  top: 34px;
}

.top-\[365px\] {
  top: 365px;
}

.top-\[369px\] {
  top: 369px;
}

.top-\[376px\] {
  top: 376px;
}

.top-\[380px\] {
  top: 380px;
}

.top-\[389px\] {
  top: 389px;
}

.top-\[398px\] {
  top: 398px;
}

.top-\[3px\] {
  top: 3px;
}

.top-\[410px\] {
  top: 410px;
}

.top-\[41px\] {
  top: 41px;
}

.top-\[429px\] {
  top: 429px;
}

.top-\[433px\] {
  top: 433px;
}

.top-\[451px\] {
  top: 451px;
}

.top-\[461px\] {
  top: 461px;
}

.top-\[464px\] {
  top: 464px;
}

.top-\[47px\] {
  top: 47px;
}

.top-\[49px\] {
  top: 49px;
}

.top-\[50px\] {
  top: 50px;
}

.top-\[512px\] {
  top: 512px;
}

.top-\[550px\] {
  top: 550px;
}

.top-\[598px\] {
  top: 598px;
}

.top-\[5px\] {
  top: 5px;
}

.top-\[624px\] {
  top: 624px;
}

.top-\[638px\] {
  top: 638px;
}

.top-\[65px\] {
  top: 65px;
}

.top-\[661px\] {
  top: 661px;
}

.top-\[70px\] {
  top: 70px;
}

.top-\[716px\] {
  top: 716px;
}

.top-\[734px\] {
  top: 734px;
}

.top-\[76px\] {
  top: 76px;
}

.top-\[772px\] {
  top: 772px;
}

.top-\[77px\] {
  top: 77px;
}

.top-\[870px\] {
  top: 870px;
}

.top-\[90px\] {
  top: 90px;
}

.top-\[9px\] {
  top: 9px;
}

.top-px {
  top: 1px;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.m-0 {
  margin: 0;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.\!mr-\[unset\] {
  margin-right: unset !important;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-\[-1\.00px\] {
  margin-bottom: -1px;
}

.mb-\[-1\.10px\] {
  margin-bottom: -1.1px;
}

.mb-\[-11\.00px\] {
  margin-bottom: -11px;
}

.mb-\[-11\.50px\] {
  margin-bottom: -11.5px;
}

.mb-\[-11\.62px\] {
  margin-bottom: -11.62px;
}

.mb-\[-12\.00px\] {
  margin-bottom: -12px;
}

.mb-\[-12\.26px\] {
  margin-bottom: -12.26px;
}

.mb-\[-34\.00px\] {
  margin-bottom: -34px;
}

.mb-\[-5\.00px\] {
  margin-bottom: -5px;
}

.mb-\[-6\.50px\] {
  margin-bottom: -6.5px;
}

.mb-\[-7\.30px\] {
  margin-bottom: -7.3px;
}

.mb-\[-7\.35px\] {
  margin-bottom: -7.35px;
}

.mb-\[-7\.50px\] {
  margin-bottom: -7.5px;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-\[-0\.50px\] {
  margin-left: -.5px;
}

.ml-\[-1\.00px\] {
  margin-left: -1px;
}

.ml-\[-2\.89px\] {
  margin-left: -2.89px;
}

.ml-\[-20\.50px\], .ml-\[-20\.5px\] {
  margin-left: -20.5px;
}

.ml-auto {
  margin-left: auto;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-\[-0\.50px\] {
  margin-right: -.5px;
}

.mr-\[-1\.00px\] {
  margin-right: -1px;
}

.mr-\[-18\.30px\] {
  margin-right: -18.3px;
}

.mr-\[-2\.89px\] {
  margin-right: -2.89px;
}

.mr-\[-20\.50px\], .mr-\[-20\.5px\] {
  margin-right: -20.5px;
}

.mr-\[-24\.62px\] {
  margin-right: -24.62px;
}

.mr-\[-36\.17px\] {
  margin-right: -36.17px;
}

.mr-\[-4\.61px\] {
  margin-right: -4.61px;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-\[-0\.75px\] {
  margin-top: -.75px;
}

.mt-\[-1\.00px\] {
  margin-top: -1px;
}

.mt-\[-11\.50px\] {
  margin-top: -11.5px;
}

.mt-\[-11\.59px\] {
  margin-top: -11.59px;
}

.mt-\[-12\.26px\] {
  margin-top: -12.26px;
}

.mt-\[-13\.00px\] {
  margin-top: -13px;
}

.mt-\[-14\.00px\] {
  margin-top: -14px;
}

.mt-\[-2\.89px\] {
  margin-top: -2.89px;
}

.mt-\[-3\.00px\] {
  margin-top: -3px;
}

.mt-\[-3\.10px\] {
  margin-top: -3.1px;
}

.mt-\[-3px\] {
  margin-top: -3px;
}

.mt-\[-4px\] {
  margin-top: -4px;
}

.mt-\[-7\.30px\] {
  margin-top: -7.3px;
}

.mt-\[-7\.50px\] {
  margin-top: -7.5px;
}

.mt-\[-8\.50px\] {
  margin-top: -8.5px;
}

.mt-\[-9\.35px\] {
  margin-top: -9.35px;
}

.mt-\[70px\] {
  margin-top: 70px;
}

.mt-\[7px\] {
  margin-top: 7px;
}

.mt-\[80px\] {
  margin-top: 80px;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.\!flex {
  display: flex !important;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.\!grid {
  display: grid !important;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.\!h-4 {
  height: 1rem !important;
}

.\!h-5 {
  height: 1.25rem !important;
}

.\!h-6 {
  height: 1.5rem !important;
}

.\!h-\[1080px\] {
  height: 1080px !important;
}

.h-0\.5 {
  height: .125rem;
}

.h-1 {
  height: .25rem;
}

.h-1\.5 {
  height: .375rem;
}

.h-10 {
  height: 2.5rem;
}

.h-11 {
  height: 2.75rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: .5rem;
}

.h-2\.5 {
  height: .625rem;
}

.h-20 {
  height: 5rem;
}

.h-3 {
  height: .75rem;
}

.h-3\.5 {
  height: .875rem;
}

.h-32 {
  height: 8rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-60 {
  height: 15rem;
}

.h-8 {
  height: 2rem;
}

.h-\[10\.75px\] {
  height: 10.75px;
}

.h-\[100px\] {
  height: 100px;
}

.h-\[1024px\] {
  height: 1024px;
}

.h-\[105px\] {
  height: 105px;
}

.h-\[1080px\] {
  height: 1080px;
}

.h-\[11\.6px\] {
  height: 11.6px;
}

.h-\[1110px\] {
  height: 1110px;
}

.h-\[115px\] {
  height: 115px;
}

.h-\[118px\] {
  height: 118px;
}

.h-\[125px\] {
  height: 125px;
}

.h-\[134px\] {
  height: 134px;
}

.h-\[138px\] {
  height: 138px;
}

.h-\[13px\] {
  height: 13px;
}

.h-\[140px\] {
  height: 140px;
}

.h-\[145px\] {
  height: 145px;
}

.h-\[147px\] {
  height: 147px;
}

.h-\[16\.42px\] {
  height: 16.42px;
}

.h-\[16\.63px\] {
  height: 16.63px;
}

.h-\[166px\] {
  height: 166px;
}

.h-\[170px\] {
  height: 170px;
}

.h-\[171px\] {
  height: 171px;
}

.h-\[18\.21px\] {
  height: 18.21px;
}

.h-\[18\.8px\] {
  height: 18.8px;
}

.h-\[185px\] {
  height: 185px;
}

.h-\[19\.52px\] {
  height: 19.52px;
}

.h-\[194px\] {
  height: 194px;
}

.h-\[198px\] {
  height: 198px;
}

.h-\[200px\] {
  height: 200px;
}

.h-\[201px\] {
  height: 201px;
}

.h-\[202px\] {
  height: 202px;
}

.h-\[211px\] {
  height: 211px;
}

.h-\[21px\] {
  height: 21px;
}

.h-\[22px\] {
  height: 22px;
}

.h-\[242px\] {
  height: 242px;
}

.h-\[243px\] {
  height: 243px;
}

.h-\[251px\] {
  height: 251px;
}

.h-\[25px\] {
  height: 25px;
}

.h-\[268px\] {
  height: 268px;
}

.h-\[26px\] {
  height: 26px;
}

.h-\[277px\] {
  height: 277px;
}

.h-\[283px\] {
  height: 283px;
}

.h-\[28px\] {
  height: 28px;
}

.h-\[293px\] {
  height: 293px;
}

.h-\[30\.75px\] {
  height: 30.75px;
}

.h-\[300px\] {
  height: 300px;
}

.h-\[308px\] {
  height: 308px;
}

.h-\[30px\] {
  height: 30px;
}

.h-\[310px\] {
  height: 310px;
}

.h-\[324px\] {
  height: 324px;
}

.h-\[328px\] {
  height: 328px;
}

.h-\[32px\] {
  height: 32px;
}

.h-\[33px\] {
  height: 33px;
}

.h-\[34px\] {
  height: 34px;
}

.h-\[350px\] {
  height: 350px;
}

.h-\[35px\] {
  height: 35px;
}

.h-\[363px\] {
  height: 363px;
}

.h-\[366px\] {
  height: 366px;
}

.h-\[367px\] {
  height: 367px;
}

.h-\[368px\] {
  height: 368px;
}

.h-\[369px\] {
  height: 369px;
}

.h-\[372px\] {
  height: 372px;
}

.h-\[373px\] {
  height: 373px;
}

.h-\[374px\] {
  height: 374px;
}

.h-\[376px\] {
  height: 376px;
}

.h-\[37px\] {
  height: 37px;
}

.h-\[385px\] {
  height: 385px;
}

.h-\[38px\] {
  height: 38px;
}

.h-\[39px\] {
  height: 39px;
}

.h-\[400px\] {
  height: 400px;
}

.h-\[401px\] {
  height: 401px;
}

.h-\[426px\] {
  height: 426px;
}

.h-\[427px\] {
  height: 427px;
}

.h-\[42px\] {
  height: 42px;
}

.h-\[440px\] {
  height: 440px;
}

.h-\[455px\] {
  height: 455px;
}

.h-\[469px\] {
  height: 469px;
}

.h-\[47px\] {
  height: 47px;
}

.h-\[489px\] {
  height: 489px;
}

.h-\[493px\] {
  height: 493px;
}

.h-\[5\.77px\] {
  height: 5.77px;
}

.h-\[509px\] {
  height: 509px;
}

.h-\[52px\] {
  height: 52px;
}

.h-\[538px\] {
  height: 538px;
}

.h-\[556px\] {
  height: 556px;
}

.h-\[6\.58px\] {
  height: 6.58px;
}

.h-\[617px\] {
  height: 617px;
}

.h-\[62\.72px\] {
  height: 62.72px;
}

.h-\[63\.6px\] {
  height: 63.6px;
}

.h-\[63\.89px\] {
  height: 63.89px;
}

.h-\[63px\] {
  height: 63px;
}

.h-\[686px\] {
  height: 686px;
}

.h-\[694px\] {
  height: 694px;
}

.h-\[70px\] {
  height: 70px;
}

.h-\[71px\] {
  height: 71px;
}

.h-\[726px\] {
  height: 726px;
}

.h-\[737px\] {
  height: 737px;
}

.h-\[73px\] {
  height: 73px;
}

.h-\[751px\] {
  height: 751px;
}

.h-\[75px\] {
  height: 75px;
}

.h-\[760px\] {
  height: 760px;
}

.h-\[76px\] {
  height: 76px;
}

.h-\[77px\] {
  height: 77px;
}

.h-\[79px\] {
  height: 79px;
}

.h-\[8\.51px\] {
  height: 8.51px;
}

.h-\[844px\] {
  height: 844px;
}

.h-\[85px\] {
  height: 85px;
}

.h-\[89px\] {
  height: 89px;
}

.h-\[90px\] {
  height: 90px;
}

.h-\[92px\] {
  height: 92px;
}

.h-\[98px\] {
  height: 98px;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.min-h-screen {
  min-height: 100vh;
}

.\!w-4 {
  width: 1rem !important;
}

.\!w-5 {
  width: 1.25rem !important;
}

.\!w-6 {
  width: 1.5rem !important;
}

.\!w-\[195px\] {
  width: 195px !important;
}

.\!w-full {
  width: 100% !important;
}

.w-0\.5 {
  width: .125rem;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/4 {
  width: 25%;
}

.w-10 {
  width: 2.5rem;
}

.w-2 {
  width: .5rem;
}

.w-2\.5 {
  width: .625rem;
}

.w-20 {
  width: 5rem;
}

.w-28 {
  width: 7rem;
}

.w-3 {
  width: .75rem;
}

.w-3\.5 {
  width: .875rem;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-52 {
  width: 13rem;
}

.w-6 {
  width: 1.5rem;
}

.w-7 {
  width: 1.75rem;
}

.w-72 {
  width: 18rem;
}

.w-8 {
  width: 2rem;
}

.w-80 {
  width: 20rem;
}

.w-96 {
  width: 24rem;
}

.w-\[104px\] {
  width: 104px;
}

.w-\[105px\] {
  width: 105px;
}

.w-\[11\.6px\] {
  width: 11.6px;
}

.w-\[111px\] {
  width: 111px;
}

.w-\[119px\] {
  width: 119px;
}

.w-\[11px\] {
  width: 11px;
}

.w-\[12\.3px\] {
  width: 12.3px;
}

.w-\[123px\] {
  width: 123px;
}

.w-\[132px\] {
  width: 132px;
}

.w-\[140px\] {
  width: 140px;
}

.w-\[141px\] {
  width: 141px;
}

.w-\[1440px\] {
  width: 1440px;
}

.w-\[145px\] {
  width: 145px;
}

.w-\[148px\] {
  width: 148px;
}

.w-\[149px\] {
  width: 149px;
}

.w-\[15\.38px\] {
  width: 15.38px;
}

.w-\[150px\] {
  width: 150px;
}

.w-\[155px\] {
  width: 155px;
}

.w-\[156px\] {
  width: 156px;
}

.w-\[1598px\] {
  width: 1598px;
}

.w-\[1609px\] {
  width: 1609px;
}

.w-\[1618px\] {
  width: 1618px;
}

.w-\[1619px\] {
  width: 1619px;
}

.w-\[1625px\] {
  width: 1625px;
}

.w-\[1678px\] {
  width: 1678px;
}

.w-\[169px\] {
  width: 169px;
}

.w-\[172px\] {
  width: 172px;
}

.w-\[173px\] {
  width: 173px;
}

.w-\[1760px\] {
  width: 1760px;
}

.w-\[18\.32px\] {
  width: 18.32px;
}

.w-\[18\.8px\] {
  width: 18.8px;
}

.w-\[1838px\] {
  width: 1838px;
}

.w-\[1839px\] {
  width: 1839px;
}

.w-\[184px\] {
  width: 184px;
}

.w-\[187px\] {
  width: 187px;
}

.w-\[18px\] {
  width: 18px;
}

.w-\[190px\] {
  width: 190px;
}

.w-\[1920px\] {
  width: 1920px;
}

.w-\[194px\] {
  width: 194px;
}

.w-\[195px\] {
  width: 195px;
}

.w-\[198px\] {
  width: 198px;
}

.w-\[199px\] {
  width: 199px;
}

.w-\[19px\] {
  width: 19px;
}

.w-\[1px\] {
  width: 1px;
}

.w-\[202px\] {
  width: 202px;
}

.w-\[204px\] {
  width: 204px;
}

.w-\[2069px\] {
  width: 2069px;
}

.w-\[21\.2px\] {
  width: 21.2px;
}

.w-\[210px\] {
  width: 210px;
}

.w-\[215px\] {
  width: 215px;
}

.w-\[220px\] {
  width: 220px;
}

.w-\[22px\] {
  width: 22px;
}

.w-\[235px\] {
  width: 235px;
}

.w-\[236px\] {
  width: 236px;
}

.w-\[238px\] {
  width: 238px;
}

.w-\[257px\] {
  width: 257px;
}

.w-\[269px\] {
  width: 269px;
}

.w-\[286px\] {
  width: 286px;
}

.w-\[296px\] {
  width: 296px;
}

.w-\[29px\] {
  width: 29px;
}

.w-\[301px\] {
  width: 301px;
}

.w-\[302px\] {
  width: 302px;
}

.w-\[305px\] {
  width: 305px;
}

.w-\[310px\] {
  width: 310px;
}

.w-\[314px\] {
  width: 314px;
}

.w-\[321px\] {
  width: 321px;
}

.w-\[324px\] {
  width: 324px;
}

.w-\[326px\] {
  width: 326px;
}

.w-\[336px\] {
  width: 336px;
}

.w-\[338px\] {
  width: 338px;
}

.w-\[340px\] {
  width: 340px;
}

.w-\[341px\] {
  width: 341px;
}

.w-\[342px\] {
  width: 342px;
}

.w-\[343px\] {
  width: 343px;
}

.w-\[347px\] {
  width: 347px;
}

.w-\[34px\] {
  width: 34px;
}

.w-\[351px\] {
  width: 351px;
}

.w-\[353px\] {
  width: 353px;
}

.w-\[354px\] {
  width: 354px;
}

.w-\[355px\] {
  width: 355px;
}

.w-\[357px\] {
  width: 357px;
}

.w-\[370px\] {
  width: 370px;
}

.w-\[385px\] {
  width: 385px;
}

.w-\[387px\] {
  width: 387px;
}

.w-\[389px\] {
  width: 389px;
}

.w-\[38px\] {
  width: 38px;
}

.w-\[390px\] {
  width: 390px;
}

.w-\[394px\] {
  width: 394px;
}

.w-\[404px\] {
  width: 404px;
}

.w-\[421px\] {
  width: 421px;
}

.w-\[432px\] {
  width: 432px;
}

.w-\[434px\] {
  width: 434px;
}

.w-\[435px\] {
  width: 435px;
}

.w-\[446px\] {
  width: 446px;
}

.w-\[467px\] {
  width: 467px;
}

.w-\[469px\] {
  width: 469px;
}

.w-\[47px\] {
  width: 47px;
}

.w-\[480px\] {
  width: 480px;
}

.w-\[489px\] {
  width: 489px;
}

.w-\[50\%\] {
  width: 50%;
}

.w-\[520px\] {
  width: 520px;
}

.w-\[558px\] {
  width: 558px;
}

.w-\[559px\] {
  width: 559px;
}

.w-\[55px\] {
  width: 55px;
}

.w-\[567px\] {
  width: 567px;
}

.w-\[56px\] {
  width: 56px;
}

.w-\[57px\] {
  width: 57px;
}

.w-\[5px\] {
  width: 5px;
}

.w-\[62\.25px\] {
  width: 62.25px;
}

.w-\[62\.72px\] {
  width: 62.72px;
}

.w-\[63\.33px\] {
  width: 63.33px;
}

.w-\[63\.6px\] {
  width: 63.6px;
}

.w-\[633px\] {
  width: 633px;
}

.w-\[65\.25px\] {
  width: 65.25px;
}

.w-\[662px\] {
  width: 662px;
}

.w-\[683px\] {
  width: 683px;
}

.w-\[68px\] {
  width: 68px;
}

.w-\[702px\] {
  width: 702px;
}

.w-\[70px\] {
  width: 70px;
}

.w-\[724px\] {
  width: 724px;
}

.w-\[73px\] {
  width: 73px;
}

.w-\[747px\] {
  width: 747px;
}

.w-\[748px\] {
  width: 748px;
}

.w-\[77px\] {
  width: 77px;
}

.w-\[791px\] {
  width: 791px;
}

.w-\[795px\] {
  width: 795px;
}

.w-\[798px\] {
  width: 798px;
}

.w-\[8\.51px\] {
  width: 8.51px;
}

.w-\[800px\] {
  width: 800px;
}

.w-\[822px\] {
  width: 822px;
}

.w-\[824px\] {
  width: 824px;
}

.w-\[836px\] {
  width: 836px;
}

.w-\[889px\] {
  width: 889px;
}

.w-\[89px\] {
  width: 89px;
}

.w-\[92px\] {
  width: 92px;
}

.w-\[949px\] {
  width: 949px;
}

.w-\[95px\] {
  width: 95px;
}

.w-\[969px\] {
  width: 969px;
}

.w-\[98px\] {
  width: 98px;
}

.w-fit {
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-px {
  width: 1px;
}

.w-screen {
  width: 100vw;
}

.min-w-full {
  min-width: 100%;
}

.max-w-\[1200px\] {
  max-width: 1200px;
}

.max-w-\[1880px\] {
  max-width: 1880px;
}

.max-w-\[350px\] {
  max-width: 350px;
}

.max-w-\[700px\] {
  max-width: 700px;
}

.max-w-\[800px\] {
  max-width: 800px;
}

.max-w-\[90\%\] {
  max-width: 90%;
}

.max-w-\[900px\] {
  max-width: 900px;
}

.max-w-\[95vw\] {
  max-width: 95vw;
}

.max-w-full {
  max-width: 100%;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-xs {
  max-width: 20rem;
}

.\!flex-1 {
  flex: 1 !important;
}

.\!flex-\[0_0_auto\] {
  flex: none !important;
}

.\!flex-\[unset\] {
  flex: unset !important;
}

.flex-1 {
  flex: 1;
}

.flex-\[0_0_auto\] {
  flex: none;
}

.flex-shrink, .shrink {
  flex-shrink: 1;
}

.flex-grow {
  flex-grow: 1;
}

.\!grow {
  flex-grow: 1 !important;
}

.grow {
  flex-grow: 1;
}

.border-collapse {
  border-collapse: collapse;
}

.border-separate {
  border-collapse: separate;
}

.border-spacing-0 {
  --tw-border-spacing-x: 0px;
  --tw-border-spacing-y: 0px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.\!transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.resize-none {
  resize: none;
}

.resize {
  resize: both;
}

.appearance-none {
  appearance: none;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.\!justify-between {
  justify-content: space-between !important;
}

.justify-between {
  justify-content: space-between;
}

.\!gap-\[unset\] {
  gap: unset !important;
}

.gap-1 {
  gap: .25rem;
}

.gap-1\.5 {
  gap: .375rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-2\.5 {
  gap: .625rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-3\.5 {
  gap: .875rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-7 {
  gap: 1.75rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-\[118px\] {
  gap: 118px;
}

.gap-\[3\.75px\] {
  gap: 3.75px;
}

.gap-\[43px\] {
  gap: 43px;
}

.gap-\[50px\] {
  gap: 50px;
}

.gap-\[61px\] {
  gap: 61px;
}

.gap-\[7\.5px\] {
  gap: 7.5px;
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.self-center {
  align-self: center;
}

.\!self-stretch {
  align-self: stretch !important;
}

.self-stretch {
  align-self: stretch;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.\!rounded-\[unset\] {
  border-radius: unset !important;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-\[0px_0px_20px_20px\] {
  border-radius: 0 0 20px 20px;
}

.rounded-\[0px_0px_33px_0px\] {
  border-radius: 0 0 33px;
}

.rounded-\[0px_20px_20px_0px\] {
  border-radius: 0 20px 20px 0;
}

.rounded-\[0px_8px_8px_0px\] {
  border-radius: 0 8px 8px 0;
}

.rounded-\[117\.5px\/120px\] {
  border-radius: 117.5px / 120px;
}

.rounded-\[19px\] {
  border-radius: 19px;
}

.rounded-\[20px\] {
  border-radius: 20px;
}

.rounded-\[20px_0px_0px_20px\] {
  border-radius: 20px 0 0 20px;
}

.rounded-\[20px_20px_0px_0px\] {
  border-radius: 20px 20px 0 0;
}

.rounded-\[28px\] {
  border-radius: 28px;
}

.rounded-\[36px\] {
  border-radius: 36px;
}

.rounded-\[374px\/120px\] {
  border-radius: 374px / 120px;
}

.rounded-\[39px\] {
  border-radius: 39px;
}

.rounded-\[40px\] {
  border-radius: 40px;
}

.rounded-\[48px\] {
  border-radius: 48px;
}

.rounded-\[62px\] {
  border-radius: 62px;
}

.rounded-\[8px_0px_0px_8px\] {
  border-radius: 8px 0 0 8px;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-b-3xl {
  border-bottom-right-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.rounded-b-lg {
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-l-2xl {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.rounded-t-3xl {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.rounded-bl-lg {
  border-bottom-left-radius: .5rem;
}

.rounded-br-lg {
  border-bottom-right-radius: .5rem;
}

.rounded-tl-lg {
  border-top-left-radius: .5rem;
}

.rounded-tr-lg {
  border-top-right-radius: .5rem;
}

.\!border {
  border-width: 1px !important;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-\[1px\] {
  border-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.\!border-solid {
  border-style: solid !important;
}

.border-solid {
  border-style: solid;
}

.border-none {
  border-style: none;
}

.\!border-\[\#575560\] {
  --tw-border-opacity: 1 !important;
  border-color: rgb(87 85 96 / var(--tw-border-opacity, 1)) !important;
}

.\!border-\[\#aff50040\] {
  border-color: #aff50040 !important;
}

.\!border-\[\#f50058\] {
  --tw-border-opacity: 1 !important;
  border-color: rgb(245 0 88 / var(--tw-border-opacity, 1)) !important;
}

.\!border-\[unset\] {
  border-color: unset !important;
}

.\!border-gray-200 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(229 231 235 / var(--tw-border-opacity, 1)) !important;
}

.\!border-gray-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1)) !important;
}

.border-\[\#232323\] {
  --tw-border-opacity: 1;
  border-color: rgb(35 35 35 / var(--tw-border-opacity, 1));
}

.border-\[\#2a2a2a\] {
  --tw-border-opacity: 1;
  border-color: rgb(42 42 42 / var(--tw-border-opacity, 1));
}

.border-\[\#302e38\] {
  --tw-border-opacity: 1;
  border-color: rgb(48 46 56 / var(--tw-border-opacity, 1));
}

.border-\[\#333333\] {
  --tw-border-opacity: 1;
  border-color: rgb(51 51 51 / var(--tw-border-opacity, 1));
}

.border-\[\#42404a\] {
  --tw-border-opacity: 1;
  border-color: rgb(66 64 74 / var(--tw-border-opacity, 1));
}

.border-\[\#464646\] {
  --tw-border-opacity: 1;
  border-color: rgb(70 70 70 / var(--tw-border-opacity, 1));
}

.border-\[\#539CFF\], .border-\[\#539cff\] {
  --tw-border-opacity: 1;
  border-color: rgb(83 156 255 / var(--tw-border-opacity, 1));
}

.border-\[\#575560\] {
  --tw-border-opacity: 1;
  border-color: rgb(87 85 96 / var(--tw-border-opacity, 1));
}

.border-\[\#575561\] {
  --tw-border-opacity: 1;
  border-color: rgb(87 85 97 / var(--tw-border-opacity, 1));
}

.border-\[\#706D85\] {
  --tw-border-opacity: 1;
  border-color: rgb(112 109 133 / var(--tw-border-opacity, 1));
}

.border-\[\#707070\] {
  --tw-border-opacity: 1;
  border-color: rgb(112 112 112 / var(--tw-border-opacity, 1));
}

.border-\[\#E1FF01\], .border-\[\#e1ff01\] {
  --tw-border-opacity: 1;
  border-color: rgb(225 255 1 / var(--tw-border-opacity, 1));
}

.border-\[\#e4ebf3\] {
  --tw-border-opacity: 1;
  border-color: rgb(228 235 243 / var(--tw-border-opacity, 1));
}

.border-\[\#ff9494\] {
  --tw-border-opacity: 1;
  border-color: rgb(255 148 148 / var(--tw-border-opacity, 1));
}

.border-\[\#ffffff1a\] {
  border-color: #ffffff1a;
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity, 1));
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity, 1));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
}

.border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity, 1));
}

.\!bg-\[\#1b2026\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(27 32 38 / var(--tw-bg-opacity, 1)) !important;
}

.\!bg-\[\#2f2e39\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(47 46 57 / var(--tw-bg-opacity, 1)) !important;
}

.\!bg-\[\#33313b40\] {
  background-color: #33313b40 !important;
}

.\!bg-\[\#aff5001a\] {
  background-color: #aff5001a !important;
}

.\!bg-\[\#e1ff01\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(225 255 1 / var(--tw-bg-opacity, 1)) !important;
}

.\!bg-\[\#f500581a\] {
  background-color: #f500581a !important;
}

.\!bg-\[unset\] {
  background-color: unset !important;
}

.\!bg-gray-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity, 1)) !important;
}

.\!bg-gray-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1)) !important;
}

.\!bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1)) !important;
}

.bg-\[\#00000026\] {
  background-color: #00000026;
}

.bg-\[\#00000066\] {
  background-color: #0006;
}

.bg-\[\#111111\] {
  --tw-bg-opacity: 1;
  background-color: rgb(17 17 17 / var(--tw-bg-opacity, 1));
}

.bg-\[\#1b1b20\] {
  --tw-bg-opacity: 1;
  background-color: rgb(27 27 32 / var(--tw-bg-opacity, 1));
}

.bg-\[\#1b2026\] {
  --tw-bg-opacity: 1;
  background-color: rgb(27 32 38 / var(--tw-bg-opacity, 1));
}

.bg-\[\#20222780\] {
  background-color: #20222780;
}

.bg-\[\#202227\] {
  --tw-bg-opacity: 1;
  background-color: rgb(32 34 39 / var(--tw-bg-opacity, 1));
}

.bg-\[\#22252A\], .bg-\[\#22252a\] {
  --tw-bg-opacity: 1;
  background-color: rgb(34 37 42 / var(--tw-bg-opacity, 1));
}

.bg-\[\#2f2e39\] {
  --tw-bg-opacity: 1;
  background-color: rgb(47 46 57 / var(--tw-bg-opacity, 1));
}

.bg-\[\#302F38\] {
  --tw-bg-opacity: 1;
  background-color: rgb(48 47 56 / var(--tw-bg-opacity, 1));
}

.bg-\[\#302e3829\] {
  background-color: #302e3829;
}

.bg-\[\#302e38\] {
  --tw-bg-opacity: 1;
  background-color: rgb(48 46 56 / var(--tw-bg-opacity, 1));
}

.bg-\[\#302f39\] {
  --tw-bg-opacity: 1;
  background-color: rgb(48 47 57 / var(--tw-bg-opacity, 1));
}

.bg-\[\#33313b40\] {
  background-color: #33313b40;
}

.bg-\[\#33323C\] {
  --tw-bg-opacity: 1;
  background-color: rgb(51 50 60 / var(--tw-bg-opacity, 1));
}

.bg-\[\#33323b\] {
  --tw-bg-opacity: 1;
  background-color: rgb(51 50 59 / var(--tw-bg-opacity, 1));
}

.bg-\[\#333\] {
  --tw-bg-opacity: 1;
  background-color: rgb(51 51 51 / var(--tw-bg-opacity, 1));
}

.bg-\[\#41404a\] {
  --tw-bg-opacity: 1;
  background-color: rgb(65 64 74 / var(--tw-bg-opacity, 1));
}

.bg-\[\#42404a\] {
  --tw-bg-opacity: 1;
  background-color: rgb(66 64 74 / var(--tw-bg-opacity, 1));
}

.bg-\[\#44424C\] {
  --tw-bg-opacity: 1;
  background-color: rgb(68 66 76 / var(--tw-bg-opacity, 1));
}

.bg-\[\#539cff73\] {
  background-color: #539cff73;
}

.bg-\[\#706D85\] {
  --tw-bg-opacity: 1;
  background-color: rgb(112 109 133 / var(--tw-bg-opacity, 1));
}

.bg-\[\#E1FF01\] {
  --tw-bg-opacity: 1;
  background-color: rgb(225 255 1 / var(--tw-bg-opacity, 1));
}

.bg-\[\#FFFFFF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.bg-\[\#aff5002b\] {
  background-color: #aff5002b;
}

.bg-\[\#aff500\] {
  --tw-bg-opacity: 1;
  background-color: rgb(175 245 0 / var(--tw-bg-opacity, 1));
}

.bg-\[\#bbc5ce\] {
  --tw-bg-opacity: 1;
  background-color: rgb(187 197 206 / var(--tw-bg-opacity, 1));
}

.bg-\[\#cfcfcf\] {
  --tw-bg-opacity: 1;
  background-color: rgb(207 207 207 / var(--tw-bg-opacity, 1));
}

.bg-\[\#e1ff01\] {
  --tw-bg-opacity: 1;
  background-color: rgb(225 255 1 / var(--tw-bg-opacity, 1));
}

.bg-\[\#ffffff66\] {
  background-color: #fff6;
}

.bg-\[rgba\(12\,12\,12\,0\.25\)\] {
  background-color: #0c0c0c40;
}

.bg-\[rgba\(48\,47\,57\,0\.25\)\] {
  background-color: #302f3940;
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity, 1));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
}

.bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity, 1));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
}

.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity, 1));
}

.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity, 1));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.bg-white\/40 {
  background-color: #fff6;
}

.bg-\[url\(\/static\/img\/container\.svg\)\] {
  background-image: url("container.86d548be.svg");
}

.bg-\[url\(\/static\/img\/glow\.svg\)\] {
  background-image: url("glow.d8ef0863.svg");
}

.bg-\[url\(\/static\/img\/mask-group-1\.png\)\] {
  background-image: url("mask-group-1.f9104266.png");
}

.bg-\[url\(\/static\/img\/mask-group\.png\)\] {
  background-image: url("mask-group.03537e7a.png");
}

.bg-\[url\(\/static\/img\/perfil\.svg\)\] {
  background-image: url("perfil.774596fb.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/2qRT4LdN\/img\/mask-group-1\.png\)\] {
  background-image: url("https://c.animaapp.com/2qRT4LdN/img/mask-group-1.png");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/Lz3jw6ZJ\/img\/mask-group-1\@2x\.png\)\] {
  background-image: url("https://c.animaapp.com/Lz3jw6ZJ/img/mask-group-1@2x.png");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/glow\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/glow.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-1\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-1.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-10\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-10.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-100\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-100.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-101\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-101.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-102\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-102.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-103\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-103.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-104\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-104.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-105\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-105.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-106\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-106.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-107\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-107.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-108\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-108.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-11\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-11.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-12\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-12.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-13\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-13.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-14\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-14.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-15\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-15.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-16\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-16.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-17\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-17.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-18\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-18.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-19\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-19.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-20\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-20.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-21\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-21.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-22\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-22.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-23\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-23.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-24\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-24.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-25\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-25.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-26\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-26.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-27\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-27.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-28\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-28.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-29\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-29.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-3\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-3.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-30\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-30.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-31\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-31.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-32\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-32.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-33\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-33.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-34\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-34.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-35\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-35.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-36\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-36.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-37\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-37.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-38\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-38.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-39\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-39.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-4\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-4.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-40\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-40.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-41\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-41.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-42\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-42.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-43\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-43.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-44\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-44.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-45\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-45.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-46\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-46.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-47\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-47.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-48\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-48.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-5\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-5.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-50\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-50.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-52\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-52.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-53\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-53.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-54\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-54.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-55\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-55.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-56\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-56.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-57\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-57.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-58\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-58.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-59\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-59.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-60\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-60.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-61\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-61.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-62\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-62.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-63\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-63.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-64\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-64.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-65\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-65.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-66\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-66.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-67\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-67.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-68\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-68.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-69\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-69.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-7\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-7.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-70\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-70.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-71\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-71.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-72\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-72.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-73\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-73.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-74\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-74.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-75\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-75.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-8\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-8.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-80\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-80.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-81\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-81.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-82\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-82.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-83\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-83.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-84\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-84.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-85\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-85.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-86\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-86.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-87\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-87.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-88\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-88.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-89\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-89.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-9\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-9.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-90\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-90.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-91\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-91.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-92\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-92.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-93\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-93.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-94\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-94.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-95\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-95.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-96\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-96.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-97\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-97.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-98\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-98.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle-99\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle-99.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/help-circle\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/help-circle.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/left-57\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/left-57.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/shape-1\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/shape-1.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/shape-10\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/shape-10.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/shape-11\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/shape-11.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/shape-12\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/shape-12.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/shape-13\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/shape-13.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/shape-2\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/shape-2.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/shape-3\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/shape-3.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/shape-4\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/shape-4.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/shape-5\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/shape-5.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/shape-6\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/shape-6.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/shape-7\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/shape-7.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/shape-8\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/shape-8.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/shape-9\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/shape-9.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/RLk3Kegs\/img\/shape\.svg\)\] {
  background-image: url("https://c.animaapp.com/RLk3Kegs/img/shape.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/Ubf4OF4q\/img\/bg\.svg\)\] {
  background-image: url("https://c.animaapp.com/Ubf4OF4q/img/bg.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/bywmmQcs\/img\/vector\.svg\)\] {
  background-image: url("https://c.animaapp.com/bywmmQcs/img/vector.svg");
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/r9Ujyu4u\/img\/mask-group-1\.png\)\] {
  background-image: url("https://c.animaapp.com/r9Ujyu4u/img/mask-group-1.png");
}

.bg-cover {
  background-size: cover;
}

.bg-\[100\%_100\%\] {
  background-position: 100% 100%;
}

.bg-\[50\%_50\%\] {
  background-position: 50%;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.object-cover {
  object-fit: cover;
}

.object-fill {
  object-fit: fill;
}

.object-center {
  object-position: center;
}

.\!p-\[unset\] {
  padding: unset !important;
}

.p-10 {
  padding: 2.5rem;
}

.p-2 {
  padding: .5rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-3 {
  padding: .75rem;
}

.p-3\.5 {
  padding: .875rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.p-\[3px\] {
  padding: 3px;
}

.p-\[7\.5px\] {
  padding: 7.5px;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-3\.5 {
  padding-left: .875rem;
  padding-right: .875rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.px-\[18px\] {
  padding-left: 18px;
  padding-right: 18px;
}

.px-\[50px\] {
  padding-left: 50px;
  padding-right: 50px;
}

.px-\[7\.5px\] {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-\[3px\] {
  padding-top: 3px;
  padding-bottom: 3px;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pl-0 {
  padding-left: 0;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-2\.5 {
  padding-left: .625rem;
}

.pl-3\.5 {
  padding-left: .875rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pr-0 {
  padding-right: 0;
}

.pr-2\.5 {
  padding-right: .625rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-3\.5 {
  padding-right: .875rem;
}

.pr-\[120px\] {
  padding-right: 120px;
}

.pt-4 {
  padding-top: 1rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-\[10px\] {
  font-size: 10px;
}

.text-\[12px\] {
  font-size: 12px;
}

.text-\[13px\] {
  font-size: 13px;
}

.text-\[15px\] {
  font-size: 15px;
}

.text-\[16px\] {
  font-size: 16px;
}

.text-\[26\.7px\] {
  font-size: 26.7px;
}

.text-\[30px\] {
  font-size: 30px;
}

.text-\[32px\] {
  font-size: 32px;
}

.text-\[40px\] {
  font-size: 40px;
}

.text-\[7\.5px\] {
  font-size: 7.5px;
}

.text-\[9\.5px\] {
  font-size: 9.5px;
}

.text-\[9px\] {
  font-size: 9px;
}

.text-\[length\:var\(--text-lg-semibold-font-size\)\] {
  font-size: var(--text-lg-semibold-font-size);
}

.text-\[length\:var\(--text-md-regular-font-size\)\] {
  font-size: var(--text-md-regular-font-size);
}

.text-\[length\:var\(--text-md-semibold-font-size\)\] {
  font-size: var(--text-md-semibold-font-size);
}

.text-\[length\:var\(--text-sm-medium-font-size\)\] {
  font-size: var(--text-sm-medium-font-size);
}

.text-\[length\:var\(--text-sm-regular-font-size\)\] {
  font-size: var(--text-sm-regular-font-size);
}

.text-\[length\:var\(--text-sm-semibold-font-size\)\] {
  font-size: var(--text-sm-semibold-font-size);
}

.text-\[length\:var\(--text-xs-regular-font-size\)\] {
  font-size: var(--text-xs-regular-font-size);
}

.text-\[length\:var\(--text-xs-semibold-font-size\)\] {
  font-size: var(--text-xs-semibold-font-size);
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.\!font-semibold {
  font-weight: 600 !important;
}

.font-\[number\:var\(--text-lg-semibold-font-weight\)\] {
  font-weight: var(--text-lg-semibold-font-weight);
}

.font-\[number\:var\(--text-md-regular-font-weight\)\] {
  font-weight: var(--text-md-regular-font-weight);
}

.font-\[number\:var\(--text-md-semibold-font-weight\)\] {
  font-weight: var(--text-md-semibold-font-weight);
}

.font-\[number\:var\(--text-sm-medium-font-weight\)\] {
  font-weight: var(--text-sm-medium-font-weight);
}

.font-\[number\:var\(--text-sm-regular-font-weight\)\] {
  font-weight: var(--text-sm-regular-font-weight);
}

.font-\[number\:var\(--text-sm-semibold-font-weight\)\] {
  font-weight: var(--text-sm-semibold-font-weight);
}

.font-\[number\:var\(--text-xs-regular-font-weight\)\] {
  font-weight: var(--text-xs-regular-font-weight);
}

.font-\[number\:var\(--text-xs-semibold-font-weight\)\] {
  font-weight: var(--text-xs-semibold-font-weight);
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

.ordinal {
  --tw-ordinal: ordinal;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.lining-nums {
  --tw-numeric-figure: lining-nums;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.tabular-nums {
  --tw-numeric-spacing: tabular-nums;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-\[28\.1px\] {
  line-height: 28.1px;
}

.leading-\[38px\] {
  line-height: 38px;
}

.leading-\[normal\] {
  line-height: normal;
}

.leading-\[var\(--text-lg-semibold-line-height\)\] {
  line-height: var(--text-lg-semibold-line-height);
}

.leading-\[var\(--text-md-regular-line-height\)\] {
  line-height: var(--text-md-regular-line-height);
}

.leading-\[var\(--text-md-semibold-line-height\)\] {
  line-height: var(--text-md-semibold-line-height);
}

.leading-\[var\(--text-sm-medium-line-height\)\] {
  line-height: var(--text-sm-medium-line-height);
}

.leading-\[var\(--text-sm-regular-line-height\)\] {
  line-height: var(--text-sm-regular-line-height);
}

.leading-\[var\(--text-sm-semibold-line-height\)\] {
  line-height: var(--text-sm-semibold-line-height);
}

.leading-\[var\(--text-xs-regular-line-height\)\] {
  line-height: var(--text-xs-regular-line-height);
}

.leading-\[var\(--text-xs-semibold-line-height\)\] {
  line-height: var(--text-xs-semibold-line-height);
}

.leading-normal {
  line-height: 1.5;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-\[0\] {
  letter-spacing: 0;
}

.tracking-\[1\.00px\] {
  letter-spacing: 1px;
}

.tracking-\[var\(--text-lg-semibold-letter-spacing\)\] {
  letter-spacing: var(--text-lg-semibold-letter-spacing);
}

.tracking-\[var\(--text-md-regular-letter-spacing\)\] {
  letter-spacing: var(--text-md-regular-letter-spacing);
}

.tracking-\[var\(--text-md-semibold-letter-spacing\)\] {
  letter-spacing: var(--text-md-semibold-letter-spacing);
}

.tracking-\[var\(--text-sm-medium-letter-spacing\)\] {
  letter-spacing: var(--text-sm-medium-letter-spacing);
}

.tracking-\[var\(--text-sm-regular-letter-spacing\)\] {
  letter-spacing: var(--text-sm-regular-letter-spacing);
}

.tracking-\[var\(--text-sm-semibold-letter-spacing\)\] {
  letter-spacing: var(--text-sm-semibold-letter-spacing);
}

.tracking-\[var\(--text-xs-regular-letter-spacing\)\] {
  letter-spacing: var(--text-xs-regular-letter-spacing);
}

.tracking-\[var\(--text-xs-semibold-letter-spacing\)\] {
  letter-spacing: var(--text-xs-semibold-letter-spacing);
}

.tracking-normal {
  letter-spacing: 0;
}

.\!text-\[\#1b2026\] {
  --tw-text-opacity: 1 !important;
  color: rgb(27 32 38 / var(--tw-text-opacity, 1)) !important;
}

.\!text-\[\#e1ff01\] {
  --tw-text-opacity: 1 !important;
  color: rgb(225 255 1 / var(--tw-text-opacity, 1)) !important;
}

.\!text-\[\#f1f1f1\] {
  --tw-text-opacity: 1 !important;
  color: rgb(241 241 241 / var(--tw-text-opacity, 1)) !important;
}

.\!text-gray-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(209 213 219 / var(--tw-text-opacity, 1)) !important;
}

.\!text-gray-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1)) !important;
}

.\!text-gray-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1)) !important;
}

.\!text-gray-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(31 41 55 / var(--tw-text-opacity, 1)) !important;
}

.\!text-gray-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(17 24 39 / var(--tw-text-opacity, 1)) !important;
}

.\!text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1)) !important;
}

.text-\[\#00000066\] {
  color: #0006;
}

.text-\[\#000000\] {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.text-\[\#1b1b20\] {
  --tw-text-opacity: 1;
  color: rgb(27 27 32 / var(--tw-text-opacity, 1));
}

.text-\[\#1b2026\] {
  --tw-text-opacity: 1;
  color: rgb(27 32 38 / var(--tw-text-opacity, 1));
}

.text-\[\#1f1e25\] {
  --tw-text-opacity: 1;
  color: rgb(31 30 37 / var(--tw-text-opacity, 1));
}

.text-\[\#22252A\], .text-\[\#22252a\] {
  --tw-text-opacity: 1;
  color: rgb(34 37 42 / var(--tw-text-opacity, 1));
}

.text-\[\#28a745\] {
  --tw-text-opacity: 1;
  color: rgb(40 167 69 / var(--tw-text-opacity, 1));
}

.text-\[\#302e38\] {
  --tw-text-opacity: 1;
  color: rgb(48 46 56 / var(--tw-text-opacity, 1));
}

.text-\[\#666666\] {
  --tw-text-opacity: 1;
  color: rgb(102 102 102 / var(--tw-text-opacity, 1));
}

.text-\[\#727272\] {
  --tw-text-opacity: 1;
  color: rgb(114 114 114 / var(--tw-text-opacity, 1));
}

.text-\[\#999999\] {
  --tw-text-opacity: 1;
  color: rgb(153 153 153 / var(--tw-text-opacity, 1));
}

.text-\[\#A0A0A0\] {
  --tw-text-opacity: 1;
  color: rgb(160 160 160 / var(--tw-text-opacity, 1));
}

.text-\[\#E1E1E1\] {
  --tw-text-opacity: 1;
  color: rgb(225 225 225 / var(--tw-text-opacity, 1));
}

.text-\[\#E1FF01\] {
  --tw-text-opacity: 1;
  color: rgb(225 255 1 / var(--tw-text-opacity, 1));
}

.text-\[\#F1F1F1\] {
  --tw-text-opacity: 1;
  color: rgb(241 241 241 / var(--tw-text-opacity, 1));
}

.text-\[\#FFFFFF\] {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.text-\[\#b2b2b2\] {
  --tw-text-opacity: 1;
  color: rgb(178 178 178 / var(--tw-text-opacity, 1));
}

.text-\[\#c7c7c7\] {
  --tw-text-opacity: 1;
  color: rgb(199 199 199 / var(--tw-text-opacity, 1));
}

.text-\[\#cccccc\] {
  --tw-text-opacity: 1;
  color: rgb(204 204 204 / var(--tw-text-opacity, 1));
}

.text-\[\#e1ff01\] {
  --tw-text-opacity: 1;
  color: rgb(225 255 1 / var(--tw-text-opacity, 1));
}

.text-\[\#f1f1f1\] {
  --tw-text-opacity: 1;
  color: rgb(241 241 241 / var(--tw-text-opacity, 1));
}

.text-\[\#ffffff66\] {
  color: #fff6;
}

.text-\[\#ffffff\] {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity, 1));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity, 1));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity, 1));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity, 1));
}

.text-transparent {
  color: #0000;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.text-white\/60 {
  color: #fff9;
}

.underline {
  text-decoration-line: underline;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.placeholder-\[\#666666\]::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(102 102 102 / var(--tw-placeholder-opacity, 1));
}

.placeholder-\[\#F1F1F1\]::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(241 241 241 / var(--tw-placeholder-opacity, 1));
}

.opacity-10 {
  opacity: .1;
}

.opacity-50 {
  opacity: .5;
}

.opacity-\[0\.2\] {
  opacity: .2;
}

.bg-blend-soft-light {
  background-blend-mode: soft-light;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0px_0px_0px_2px_\#e1ff01\] {
  --tw-shadow: 0px 0px 0px 2px #e1ff01;
  --tw-shadow-colored: 0px 0px 0px 2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0px_20px_24px_-4px_\#00000052\] {
  --tw-shadow: 0px 20px 24px -4px #00000052;
  --tw-shadow-colored: 0px 20px 24px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.\!shadow-\[unset\] {
  --tw-shadow-color: unset !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.outline {
  outline-style: solid;
}

.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-\[400px\] {
  --tw-blur: blur(400px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.brightness-110 {
  --tw-brightness: brightness(1.1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px #0000001a) drop-shadow(0 1px 1px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.grayscale {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.invert {
  --tw-invert: invert(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.sepia {
  --tw-sepia: sepia(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur-\[15px\] {
  --tw-backdrop-blur: blur(15px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-\[200px\] {
  --tw-backdrop-blur: blur(200px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-\[450\.6px\] {
  --tw-backdrop-blur: blur(450.6px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-brightness-\[100\%\] {
  --tw-backdrop-brightness: brightness(100%);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.\!transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter !important;
  transition-duration: .15s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.\[-webkit-backdrop-filter\:blur\(200px\)_brightness\(100\%\)\] {
  -webkit-backdrop-filter: blur(200px) brightness();
}

.\[-webkit-backdrop-filter\:blur\(450\.6px\)_brightness\(100\%\)\] {
  -webkit-backdrop-filter: blur(450.6px) brightness();
}

.\!\[-webkit-text-stroke\:1px_\#33313b\] {
  -webkit-text-stroke: 1px #33313b !important;
}

.\[background\:linear-gradient\(180deg\,rgba\(0\,0\,0\,0\)_0\%\,rgba\(175\,245\,0\,0\.07\)_25\.84\%\,rgba\(148\.86\,208\.41\,0\,0\.06\)_51\.35\%\,rgba\(0\,0\,0\,0\)_100\%\)\] {
  background: linear-gradient(#0000 0%, #aff50012 25.84%, #95d0000f 51.35%, #0000 100%);
}

.\[font-family\:\'Inter\'\,Helvetica\] {
  font-family: Inter, Helvetica;
}

.\[font-family\:\'Neue_Machina-Bold\'\,Helvetica\] {
  font-family: Neue Machina-Bold, Helvetica;
}

.\!\[font-family\:\'Poppins\'\,Helvetica\] {
  font-family: Poppins, Helvetica !important;
}

.\[font-family\:\'Poppins\'\,Helvetica\] {
  font-family: Poppins, Helvetica;
}

.\[font-style\:var\(--text-lg-semibold-font-style\)\] {
  font-style: var(--text-lg-semibold-font-style);
}

.\[font-style\:var\(--text-md-regular-font-style\)\] {
  font-style: var(--text-md-regular-font-style);
}

.\[font-style\:var\(--text-md-semibold-font-style\)\] {
  font-style: var(--text-md-semibold-font-style);
}

.\[font-style\:var\(--text-sm-medium-font-style\)\] {
  font-style: var(--text-sm-medium-font-style);
}

.\[font-style\:var\(--text-sm-regular-font-style\)\] {
  font-style: var(--text-sm-regular-font-style);
}

.\[font-style\:var\(--text-sm-semibold-font-style\)\] {
  font-style: var(--text-sm-semibold-font-style);
}

.\[font-style\:var\(--text-xs-regular-font-style\)\] {
  font-style: var(--text-xs-regular-font-style);
}

.\[font-style\:var\(--text-xs-semibold-font-style\)\] {
  font-style: var(--text-xs-semibold-font-style);
}

.\!\[overflow\:unset\] {
  overflow: unset !important;
}

.\!\[text-shadow\:0px_0px_0px_4px_\#f4ebff\] {
  text-shadow: 0 0 0 4px #f4ebff !important;
}

.\[text-shadow\:0px_0px_0px_4px_\#f4ebff\] {
  text-shadow: 0 0 0 4px #f4ebff;
}

.\!\[text-shadow\:0px_0px_0px_4px_\#fee3e1\] {
  text-shadow: 0 0 0 4px #fee3e1 !important;
}

.\[text-shadow\:0px_0px_0px_4px_\#fee3e1\] {
  text-shadow: 0 0 0 4px #fee3e1;
}

.\!\[white-space\:unset\] {
  white-space: unset !important;
}

.file\:mr-4::file-selector-button {
  margin-right: 1rem;
}

.file\:rounded-xl::file-selector-button {
  border-radius: .75rem;
}

.file\:border-0::file-selector-button {
  border-width: 0;
}

.file\:bg-\[\#e1ff01\]::file-selector-button {
  --tw-bg-opacity: 1;
  background-color: rgb(225 255 1 / var(--tw-bg-opacity, 1));
}

.file\:px-4::file-selector-button {
  padding-left: 1rem;
  padding-right: 1rem;
}

.file\:py-2::file-selector-button {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.file\:text-sm::file-selector-button {
  font-size: .875rem;
  line-height: 1.25rem;
}

.file\:font-semibold::file-selector-button {
  font-weight: 600;
}

.file\:text-\[\#302e38\]::file-selector-button {
  --tw-text-opacity: 1;
  color: rgb(48 46 56 / var(--tw-text-opacity, 1));
}

.checked\:border-none:checked {
  border-style: none;
}

.checked\:bg-\[\#e1ff01\]:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(225 255 1 / var(--tw-bg-opacity, 1));
}

.focus-within\:ring-2:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-within\:ring-\[\#e1ff01\]:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(225 255 1 / var(--tw-ring-opacity, 1));
}

.hover\:scale-105:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:bg-\[\#0056b3\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 86 179 / var(--tw-bg-opacity, 1));
}

.hover\:bg-\[\#33323b\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(51 50 59 / var(--tw-bg-opacity, 1));
}

.hover\:bg-\[\#333\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(51 51 51 / var(--tw-bg-opacity, 1));
}

.hover\:bg-\[\#3a383d\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(58 56 61 / var(--tw-bg-opacity, 1));
}

.hover\:bg-\[\#3c3c47\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(60 60 71 / var(--tw-bg-opacity, 1));
}

.hover\:bg-\[\#42404a\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(66 64 74 / var(--tw-bg-opacity, 1));
}

.hover\:bg-\[\#44424c\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(68 66 76 / var(--tw-bg-opacity, 1));
}

.hover\:bg-\[\#444\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(68 68 68 / var(--tw-bg-opacity, 1));
}

.hover\:bg-\[\#539CFF\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(83 156 255 / var(--tw-bg-opacity, 1));
}

.hover\:bg-\[\#5b5a63\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(91 90 99 / var(--tw-bg-opacity, 1));
}

.hover\:bg-\[\#B4B4B4\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(180 180 180 / var(--tw-bg-opacity, 1));
}

.hover\:bg-\[\#E1FF01\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(225 255 1 / var(--tw-bg-opacity, 1));
}

.hover\:bg-\[\#a6b4c0\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(166 180 192 / var(--tw-bg-opacity, 1));
}

.hover\:bg-\[\#a8bf00\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(168 191 0 / var(--tw-bg-opacity, 1));
}

.hover\:bg-\[\#b8cc00\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(184 204 0 / var(--tw-bg-opacity, 1));
}

.hover\:bg-\[\#b8dd01\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(184 221 1 / var(--tw-bg-opacity, 1));
}

.hover\:bg-\[\#c6e600\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(198 230 0 / var(--tw-bg-opacity, 1));
}

.hover\:bg-\[\#d1d100\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(209 209 0 / var(--tw-bg-opacity, 1));
}

.hover\:bg-\[\#d1e600\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(209 230 0 / var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
}

.hover\:text-\[\#22252A\]:hover {
  --tw-text-opacity: 1;
  color: rgb(34 37 42 / var(--tw-text-opacity, 1));
}

.hover\:text-\[\#E1FF01\]:hover {
  --tw-text-opacity: 1;
  color: rgb(225 255 1 / var(--tw-text-opacity, 1));
}

.hover\:text-\[\#a8bf00\]:hover {
  --tw-text-opacity: 1;
  color: rgb(168 191 0 / var(--tw-text-opacity, 1));
}

.hover\:text-black:hover {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.hover\:text-gray-300:hover {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity, 1));
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}

.hover\:text-red-700:hover {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity, 1));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:opacity-75:hover {
  opacity: .75;
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:brightness-110:hover {
  --tw-brightness: brightness(1.1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-\[\#e1ff01\]:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(225 255 1 / var(--tw-ring-opacity, 1));
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.active\:bg-\[\#1a1a1f\]:active {
  --tw-bg-opacity: 1;
  background-color: rgb(26 26 31 / var(--tw-bg-opacity, 1));
}

.disabled\:opacity-50:disabled {
  opacity: .5;
}

.group:hover .group-hover\:scale-110 {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@media (width >= 640px) {
  .sm\:mr-2 {
    margin-right: .5rem;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:h-12 {
    height: 3rem;
  }

  .sm\:h-3 {
    height: .75rem;
  }

  .sm\:h-64 {
    height: 16rem;
  }

  .sm\:h-\[35px\] {
    height: 35px;
  }

  .sm\:w-3 {
    width: .75rem;
  }

  .sm\:w-\[160px\] {
    width: 160px;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:justify-between {
    justify-content: space-between;
  }

  .sm\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.5rem * var(--tw-space-x-reverse));
    margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .sm\:p-3 {
    padding: .75rem;
  }

  .sm\:p-5 {
    padding: 1.25rem;
  }

  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .sm\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .sm\:pr-\[160px\] {
    padding-right: 160px;
  }

  .sm\:text-left {
    text-align: left;
  }

  .sm\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .sm\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .sm\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .sm\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .sm\:text-xs {
    font-size: .75rem;
    line-height: 1rem;
  }
}

@media (width >= 768px) {
  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:inline-flex {
    display: inline-flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-12 {
    height: 3rem;
  }

  .md\:h-14 {
    height: 3.5rem;
  }

  .md\:h-\[37px\] {
    height: 37px;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-\[200px\] {
    width: 200px;
  }

  .md\:w-\[336px\] {
    width: 336px;
  }

  .md\:w-\[48\%\] {
    width: 48%;
  }

  .md\:w-\[500px\] {
    width: 500px;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:items-start {
    align-items: flex-start;
  }

  .md\:gap-8 {
    gap: 2rem;
  }

  .md\:p-8 {
    padding: 2rem;
  }

  .md\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:pr-\[250px\] {
    padding-right: 250px;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .md\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .md\:text-xs {
    font-size: .75rem;
    line-height: 1rem;
  }
}

@media (width >= 1024px) {
  .lg\:relative {
    position: relative;
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:ml-4 {
    margin-left: 1rem;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-screen {
    height: 100vh;
  }

  .lg\:w-36 {
    width: 9rem;
  }

  .lg\:w-\[180px\] {
    width: 180px;
  }

  .lg\:w-\[210px\] {
    width: 210px;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:max-w-\[1880px\] {
    max-width: 1880px;
  }

  .lg\:max-w-\[380px\] {
    max-width: 380px;
  }

  .lg\:max-w-\[420px\] {
    max-width: 420px;
  }

  .lg\:max-w-\[48\%\] {
    max-width: 48%;
  }

  .lg\:max-w-lg {
    max-width: 32rem;
  }

  .lg\:translate-x-0 {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:items-start {
    align-items: flex-start;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:gap-10 {
    gap: 2.5rem;
  }

  .lg\:gap-7 {
    gap: 1.75rem;
  }

  .lg\:self-start {
    align-self: flex-start;
  }

  .lg\:p-6 {
    padding: 1.5rem;
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .lg\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .lg\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .lg\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .lg\:pr-\[270px\] {
    padding-right: 270px;
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .lg\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .lg\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (width >= 1280px) {
  .xl\:max-w-xl {
    max-width: 36rem;
  }

  .xl\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .xl\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .xl\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
/*# sourceMappingURL=index.e13f0a9f.css.map */
